//import clsx from 'clsx';
/*import { useContext } from 'react';
import { SimulationContext } from '../../contexts/SimulationContext';
import {
  DomainDetails,
  VerifiedDomains,
} from '../../helpers/constants/verifiedDomains';
import { TrustLevelEnum } from '../../helpers/enums/application.enums';
import { signatureIcon } from '../../helpers/icons/icons';
import {
  //contractBannerColor,
  //contractTextColor,
  getBaseUrl,
  warningIcon,
} from '../../helpers/methods';*/
import DomainCard from './DomainCard';

interface IGenericBannerProps {
  sourceUrl: string | undefined;
  header: string;
}

const GenericBanner: React.FC<IGenericBannerProps> = ({
  sourceUrl,
  //header,
}) => {
  /*const { eipGlobalInformation } = useContext(SimulationContext);
  const displayWarningBanner = [
    TrustLevelEnum.LOW,
    TrustLevelEnum.DANGER,
  ].includes(eipGlobalInformation.securityRiskData.globalTrustLevel);*/

  //const icon = warningIcon(displayWarningBanner);
  /*const backgroundColor = contractBannerColor(displayWarningBanner);
  const color = contractTextColor(displayWarningBanner);*/
  //const dappUrl = getBaseUrl(sourceUrl ?? '');
  //const verifiedDappData: DomainDetails | undefined = VerifiedDomains[dappUrl];
  //const iconUrl = `/dAppDomainIcons/${verifiedDappData?.icon}`;
  /*return (
    <div
      className={clsx(
        `h-[148px p-4 flex flex-col items-center justify-center`,
        displayWarningBanner ? backgroundColor : 'bg-secondary-800'
      )}
    >
      <div
        className={clsx(
          `text-2xl flex flex-col justify-center items-center font-ClashDisplay`,
          displayWarningBanner ? color : 'text-white'
        )}
      >
        {displayWarningBanner ? (
          icon
        ) : verifiedDappData ? (
          <img src={iconUrl} alt="" className="w-[25px] h-[25px] mb-2" />
        ) : (
          <div className="mb-2">{signatureIcon}</div>
        )}
        <div>{displayWarningBanner ? 'Unsafe ' + header : header}</div>
      </div>
      <DomainCard sourceUrl={sourceUrl} />
    </div>
  );*/
  /*return (
    <div className="interacting-header">
      <div className="interacting-title">
        {displayWarningBanner ? (
          icon
        ) : verifiedDappData ? (
          <img src={iconUrl} alt="" className="w-[25px] h-[25px] mb-2" />
        ) : (
          <div className="mb-2">{signatureIcon}</div>
        )}
        <div>{displayWarningBanner ? 'Unsafe ' + header : header}</div>
      </div>
      <div className="interacting-domain-wrapper">
        {sourceUrl && <DomainCard sourceUrl={sourceUrl} />}
      </div>
    </div>
  );*/

  return (
    <div className="interacting-header">
      <div className="interacting-title">Interacting With</div>
      <div className="interacting-domain-wrapper">
        {sourceUrl && <DomainCard sourceUrl={sourceUrl} />}
      </div>
    </div>
  );
};

export default GenericBanner;
