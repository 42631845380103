import { VerifiedDomains } from '../../helpers/constants/verifiedDomains';
import { defaultApprovalIcon } from '../../helpers/icons/icons';

interface ICurrencyLogoProps {
  className?: string | undefined;
  type: string;
  searchType: 'name';
  identifier: string | null | undefined;
}

const defaultLogos = new Map<string, any>([['revokal', defaultApprovalIcon]]);

const CurrencyLogo: React.FC<ICurrencyLogoProps> = ({
  type,
  searchType,
  identifier,
  className,
}) => {
  let info;
  let iconUrl;
  const searchByName = (
    name: string
  ): { name: string; icon: string } | undefined => {
    return Object.values(VerifiedDomains).find(
      (domain) => domain.name === name
    );
  };

  if (searchType === 'name') {
    info = searchByName(identifier ?? '');
    iconUrl = `/dAppDomainIcons/${info?.icon}`;
  }

  return info ? (
    <img src={iconUrl} alt="" className={className} />
  ) : (
    <div className="">{defaultLogos.get(type)}</div>
  );
};

export default CurrencyLogo;
