import Layout from 'components/Containers/Layout';
import logoSentinel3 from 'images/logo-sentinel3.svg';
import logoSentinel3D from 'images/logo-sentinel3-d.svg';
import { useContext, useEffect, useState } from 'react';
import { SimulationContext } from 'contexts/SimulationContext';
import {
  freeVersionIcon,
  proVersionIcon,
  darkModeIcon,
  lightModeIcon,
} from 'helpers/icons/icons';

const SkeletonBanner = () => {
  const { eipGlobalInformation } = useContext(SimulationContext);
  const [theme, setTheme] = useState(eipGlobalInformation?.theme);

  const userVersion = eipGlobalInformation?.isFreeUser ? 'Free' : 'Pro';

  const isFreeUser = eipGlobalInformation?.isFreeUser;

  useEffect(() => {
    if (theme == 'dark') {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [theme]);

  const changeTheme = () => {
    let newTheme: 'light' | 'dark' = theme == 'light' ? 'dark' : 'light';
    setTheme(newTheme);

    window.parent.postMessage(
      {
        type: 'THEME_SWITCH_CHANGED',
        payload: newTheme,
      },
      '*'
    );
  };

  return (
    <Layout>
      {/*<Sunset />*/}
      <div className="header-wrapper">
        <div className="header flex flex-column">
          <div className="logo-container flex">
            <div className="logo">
              <a
                className="flex align-center justify-center"
                href="http://kerberus.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logoSentinel3} className="no-dark" />
                <img src={logoSentinel3D} className="dark" />
              </a>
            </div>
            <div className="spacer flex-1"></div>
            <div className="flex header-right">
              <div className="tag-version">
                {isFreeUser ? freeVersionIcon : proVersionIcon}
                <span>{userVersion}</span>
              </div>
              {!isFreeUser && (
                <div className="tag-version">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.49954 9H21.4995M9.99954 3L7.99954 9L11.9995 20.5L15.9995 9L13.9995 3M12.6141 20.2625L21.5727 9.51215C21.7246 9.32995 21.8005 9.23885 21.8295 9.13717C21.8551 9.04751 21.8551 8.95249 21.8295 8.86283C21.8005 8.76114 21.7246 8.67005 21.5727 8.48785L17.2394 3.28785C17.1512 3.18204 17.1072 3.12914 17.0531 3.09111C17.0052 3.05741 16.9518 3.03238 16.8953 3.01717C16.8314 3 16.7626 3 16.6248 3H7.37424C7.2365 3 7.16764 3 7.10382 3.01717C7.04728 3.03238 6.99385 3.05741 6.94596 3.09111C6.89192 3.12914 6.84783 3.18204 6.75966 3.28785L2.42633 8.48785C2.2745 8.67004 2.19858 8.76114 2.16957 8.86283C2.144 8.95249 2.144 9.04751 2.16957 9.13716C2.19858 9.23885 2.2745 9.32995 2.42633 9.51215L11.385 20.2625C11.596 20.5158 11.7015 20.6424 11.8279 20.6886C11.9387 20.7291 12.0603 20.7291 12.1712 20.6886C12.2975 20.6424 12.4031 20.5158 12.6141 20.2625Z"
                      stroke="#356AF3"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span>$30k Covered</span>
                </div>
              )}
              <div className="free-dark-mode" onClick={changeTheme}>
                {theme == 'dark' ? lightModeIcon : darkModeIcon}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="skeleton flex flex-column gap-24">
        <div className="flex flex-column gap-16">
          <div className="flex flex-column gap-8">
            <div className="skeleton-item w-[79px] h-[16px] rounded-lg animate-tailwindPulse" />
            <div className="flex gap-8 align-center">
              <div className="skeleton-item w-[110px] h-[28px] rounded-lg animate-tailwindPulse" />
              <div className="skeleton-item w-[16px] h-[16px] rounded-lg animate-tailwindPulse" />
            </div>
          </div>
          <div className="skeleton-divider"></div>
        </div>
        <div className="flex flex-column gap-8">
          <div className="skeleton-item w-[111px] h-[16px] rounded-lg animate-tailwindPulse"></div>
          <div className="skeleton-item w-full h-[64px] rounded-lg animate-tailwindPulse"></div>
        </div>
        <div className="flex flex-column gap-8">
          <div className="skeleton-item w-[111px] h-[16px] rounded-lg animate-tailwindPulse"></div>
          <div className="skeleton-item w-full h-[64px] rounded-lg animate-tailwindPulse"></div>
        </div>
        <div className="skeleton-divider"></div>
        <div className="flex flex-column gap-8">
          <div className="skeleton-item w-full h-[48px] rounded-lg animate-tailwindPulse"></div>
        </div>
      </div>
    </Layout>
  );
};

export default SkeletonBanner;
