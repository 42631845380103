import { KERBERUS_SUPPORT_URL } from '../../helpers/constants/application.constants';
import { ErrorTypes } from '../../helpers/enums/application.enums';
import {
  GeneralSimulationError,
  InsufficientFunds,
  InsufficientGasLimitError,
  NotVerified,
  RateLimited,
} from '../../helpers/icons/icons';
import { ISimulationError } from '../../helpers/interfaces/dataTypes.interface';
import { formatCurrency } from '../../helpers/methods';

export const getErrorInformation = (
  response: any,
  error: any,
  tokenInfo: any
): ISimulationError | undefined => {
  const errorType = getErrorType(response, error);
  return errorType
    ? buildErrorInformation(tokenInfo, errorType, error)
    : undefined;
};

const getErrorType = (response: any, error: any): ErrorTypes | undefined => {
  const statusCode = error
    ? error?.response?.data?.statusCode
    : response.data.statusCode;

  //Rate Limited
  if (statusCode === 429) {
    return ErrorTypes.RATE_LIMIT;
  }

  //General Error
  if (response?.data?.statusCode && response?.data?.statusCode !== 200) {
    return ErrorTypes.GENERAL_ERROR;
  }

  if (error) {
    //Return general error if no error response
    if (!error.response) {
      return ErrorTypes.GENERAL_ERROR;
    }

    const errorMessage = error.response?.data?.message;

    if (errorMessage) {
      let errorMsg;
      if (typeof errorMessage === 'string') {
        errorMsg = errorMessage.toLowerCase();
      } else errorMsg = errorMessage;

      if (errorMsg.includes(ErrorTypes.INSUFFICIENT_FUNDS.toLowerCase()))
        return ErrorTypes.INSUFFICIENT_FUNDS;
      else if (errorMsg.includes(ErrorTypes.INSUFFICIENT_BALANCE.toLowerCase()))
        return ErrorTypes.INSUFFICIENT_BALANCE;
      else if (errorMsg.includes(ErrorTypes.NOT_VERIFIED.toLowerCase()))
        return ErrorTypes.NOT_VERIFIED;
      else if (
        errorMsg.includes(ErrorTypes.INSUFFICIENT_GAS_LIMIT.toLowerCase())
      )
        return ErrorTypes.INSUFFICIENT_GAS_LIMIT;
      else return ErrorTypes.GENERAL_ERROR;
    } else return ErrorTypes.GENERAL_ERROR; //If we can't match the error, but know one has been caught, return the general error
  }
};

const buildErrorInformation = (
  tokenInfo: any,
  errorType: ErrorTypes | undefined,
  error: any
): ISimulationError | undefined => {
  if (!errorType) return;

  switch (errorType) {
    case ErrorTypes.RATE_LIMIT: {
      return {
        errorIcon: RateLimited,
        title: ErrorTypes.RATE_LIMIT,
        messageComponent: (
          <>
            Looks like you are using Sentinel3 a lot. Wait a few minutes and you
            will be able to simulate more transactions!
          </>
        ),
      };
    }
    case ErrorTypes.INSUFFICIENT_FUNDS: {
      const errorMessage = error.response?.data?.message;
      const value = formatCurrency(errorMessage.split(' ')[15]);
      const balance = formatCurrency(errorMessage.split(' ')[13]);

      return {
        errorIcon: InsufficientFunds,
        title: ErrorTypes.INSUFFICIENT_FUNDS,
        messageComponent: (
          <div className="text-secondary-800">
            Insufficient funds for transaction. You need{' '}
            <strong>{`${value} ${tokenInfo.symbol}`}</strong>, but you only have{' '}
            <strong>{`${balance} ${tokenInfo.symbol}`}</strong>
          </div>
        ),
      };
    }

    case ErrorTypes.INSUFFICIENT_BALANCE: {
      return {
        errorIcon: InsufficientFunds,
        title: ErrorTypes.INSUFFICIENT_BALANCE,
        messageComponent: <>Insufficient balance for transaction</>,
      };
    }

    case ErrorTypes.NOT_VERIFIED: {
      const errorResponse = JSON.parse(error?.response?.config?.data);

      return {
        errorIcon: NotVerified,
        title: ErrorTypes.NOT_VERIFIED,
        messageComponent: (
          <div className="text-secondary-800">
            This is an unverified contract. You can check it out{' '}
            <a
              href={`${tokenInfo.explorer}address/${errorResponse.to}`}
              target="_blank"
              style={{ color: 'inherit' }}
            >
              <u>here</u>
            </a>
            . Since it is unverified, be extra careful interacting with it!
          </div>
        ),
      };
    }

    case ErrorTypes.INSUFFICIENT_GAS_LIMIT: {
      const transactionDetail =
        error.response?.data?.information?.balanceChanges[0];
      const gasFees = formatCurrency(
        transactionDetail?.value,
        transactionDetail?.token?.decimals
      );
      return {
        errorIcon: InsufficientGasLimitError,
        title: ErrorTypes.INSUFFICIENT_GAS_LIMIT,
        messageComponent: (
          <div className="text-secondary-800">
            <p>
              Insufficient Gas limit in your wallet to complete this
              transaction.
            </p>
            <p>
              Increase the gas limit in your wallet or rerun the simulation so
              you do not waste{' '}
              <strong>
                {`${gasFees} ${transactionDetail.token?.symbol}`}{' '}
              </strong>
              in gas fees!
            </p>
          </div>
        ),
      };
    }

    default: {
      return {
        errorIcon: GeneralSimulationError,
        title: ErrorTypes.GENERAL_ERROR,
        messageComponent: (
          <div className="text-secondary-800">
            <div className="mb-2 font-bold text-secondary-800">
              How to proceed:
            </div>
            <ol className="list-decimal list-inside text-left space-y-1">
              <li>Reject your transaction.</li>
              <li>Rerun the most recent contract interaction.</li>
              <li>
                If the error keeps ocurring, report it{' '}
                <a
                  href={KERBERUS_SUPPORT_URL}
                  target="_blank"
                  style={{ color: 'inherit' }}
                >
                  <u>here!</u>
                </a>
              </li>
            </ol>
          </div>
        ),
      };
    }
  }
};
