import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  getSimulatedEipTransaction,
  getSimulatedSignature,
} from '../api/simulator';
import { getErrorInformation } from '../components/Error/errorHelper';
//import GlobalLoader from '../components/Loader/GlobalLoader';
import { DEFAULT_EIP_GLOBAL_INFORMATION } from '../helpers/SimulationsHelper/dataDictionaries';
import { NETWORK_INFO } from '../helpers/constants/application.constants';
import { getSecurityRisk } from '../helpers/engines/securityEngine';
import {
  DeepSimulationType,
  EIPTransactionTypes,
  SignatureTypes,
  WalletProvidersEnum,
} from '../helpers/enums/application.enums';
import {
  DeepSimulation,
  EIPGlobalInformation,
  SimulationData,
  SimulationRequest,
  Transaction,
} from '../helpers/interfaces/dataTypes.interface';
import { getAddressLabel, parseSpenderAddress } from '../helpers/methods';
import { checkEIP4361 } from '../helpers/personalSignatureHelper';
import SkeletonBanner from 'components/Loader/SkeletonBanner';

interface ISimulationContextProps {
  eipGlobalInformation: EIPGlobalInformation;
  transactionSimulation: SimulationData | undefined;
  transactionType: string;
}

//Create global react context
const SimulationContext = React.createContext<ISimulationContextProps>(
  {} as ISimulationContextProps
);

interface ISimulationProviderProps {
  children: React.ReactNode;
}

//Set Global state
const SimulationProvider: React.FC<ISimulationProviderProps> = ({
  children,
}) => {
  const [eipGlobalInformation, setEIPGlobalInformation] =
    useState<EIPGlobalInformation>(DEFAULT_EIP_GLOBAL_INFORMATION);
  const [transactionType, setTransactionType] = useState('');

  const [transactionSimulation, setTransactionSimulation] =
    useState<SimulationData>();

  /**
   * Update global translation information
   */
  const updateEIPInformation = async (value: Partial<EIPGlobalInformation>) => {
    return setEIPGlobalInformation((prevState) => ({
      ...prevState,
      ...value,
    }));
  };

  useEffect(() => {
    window.addEventListener('message', function (event) {
      if (event.data.type === 'initialData') {
        //console.log('iframe initialData', event.data.data);
        const transactionRequest: SimulationRequest = event.data.data;

        iframeTransactionSimulation(transactionRequest);

        //respond to the extension indicating we have received the request
        window.parent.postMessage(
          {
            type: 'response',
            message: 'RPC request received successfully',
            payload: event.data.data,
          },
          '*'
        );
      }
    });
  });

  /**
   * Simulate a transaction based on the provided payload
   * @param payload - Data payload for simulation
   */
  const iframeTransactionSimulation = async (payload: SimulationRequest) => {
    //console.log('iframeTransactionSimulation', payload);
    // Check if payload is for a signature or transaction
    const signatureTypes = Object.values(SignatureTypes);
    const requestType = signatureTypes.includes(payload.rpcMethod as any)
      ? 'signature'
      : 'transaction';

    // Set default states based on the request type
    setDefaultStates(payload, requestType);

    if (requestType === 'signature') {
      // Simulate signature if the request is for a signature
      const simulatedSignature = await getSignatureSimulation(
        payload.data as string[],
        payload.hexChainId
      );
      updateEIPInformation({
        loading: false,
      });

      // Update state with simulated signature data
      setSignatureSimulatedState(simulatedSignature?.data as any);
    } else {
      // Simulate transaction if the request is for a transaction
      await getTransactionSimulation(
        payload.data[0] as Transaction,
        payload.hexChainId
      );
      updateEIPInformation({
        loading: false,
      });
    }
  };

  /**
   * Set the state after simulating a signature
   * @param simulation - Simulation data to update state with
   */
  const setSignatureSimulatedState = async (
    simulation: SimulationData | undefined
  ) => {
    if (!simulation) {
      return;
    }

    // Update transaction simulation state
    setTransactionSimulation(simulation);

    // Update EIP information with 'from' field
    updateEIPInformation({
      from: simulation?.from,
    });

    // Update EIP information with contract address, spender, and deep simulation data
    updateEIPInformation({
      contractAddress: simulation?.to,
      spender: simulation?.spender,
      deepSimulationData: simulation.deepSimulationData
        ? (simulation.deepSimulationData as DeepSimulation)
        : undefined,
    });

    // Set transaction data based on simulation
    setTransactionData(simulation);
  };

  const setDefaultStates = (
    payload: SimulationRequest,
    requestType: 'transaction' | 'signature'
  ) => {
    const securityRiskData = getSecurityRisk(payload);
    //console.log('securityRiskData', securityRiskData);

    updateEIPInformation({
      network: payload.hexChainId,
      loading: true,
      downloadId: payload.downloadId,
      sourceUrl: payload.sourceUrl,
      payload: payload,
      isFireWalletSimulation:
        payload.walletProvider === WalletProvidersEnum.FIRE_WALLET,
      securityRiskData,
      isFreeUser: payload.isFreeUser,
      theme: payload.theme,
      fees: payload.fees,
    });
    //console.log('payload', payload);

    try {
      if (payload.rpcMethod === 'personal_sign') {
        const signature = payload.data as any;
        const isSiweSignature = checkEIP4361({ data: signature[0] });

        setTransactionType(
          isSiweSignature.isSIWEMessage
            ? SignatureTypes.EIP_4361_PERSONAL_SIGN
            : SignatureTypes.EIP_191_PERSONAL_SIGN
        );
        updateEIPInformation({
          from: signature[1],
          type: payload.rpcMethod,
          rawData: JSON.stringify(signature, null, 2),
        });
      } else if (requestType === 'transaction') {
        const transaction = payload.data as Transaction;
        updateEIPInformation({
          from: transaction.from,
          contractAddress: transaction.to,
          rawData: JSON.stringify(transaction, null, 2),
        });
      } else if (payload.rpcMethod === 'eth_signTypedData') {
        const signature = payload.data as any;
        const typedDataJson = JSON.stringify(signature[0]);
        setTransactionType(payload.rpcMethod);
        updateEIPInformation({
          from: signature[1],
          type: payload.rpcMethod,
          spender: parseSpenderAddress(typedDataJson),
          rawData: typedDataJson,
          primaryType: 'eth_signTypedData',
        });
      } else if (payload.rpcMethod === EIPTransactionTypes.ETH_SIG) {
        const signature = payload.data as any;
        setTransactionType(EIPTransactionTypes.ETH_SIG);
        updateEIPInformation({
          from: signature[0],
          type: payload.rpcMethod,
        });
      } else {
        const signature = payload.data as any;
        setTransactionType(payload.rpcMethod);
        updateEIPInformation({
          from: signature[0],
          type: payload.rpcMethod,
          spender: parseSpenderAddress(signature[1]),
          rawData: JSON.stringify(JSON.parse(signature[1]), null, 2),
          primaryType: JSON.parse(signature[1]).primaryType,
        });
      }
    } catch (error) {
      console.error('Error in setDefaultStates', error);
    }
  };

  const debugLog = (msg: any) => {
    if (
      ['development', 'staging'].includes(process.env.REACT_APP_STAGE ?? '') ||
      window.location.href.includes('staging')
    ) {
      console.log('debugging: logging API response --> ', msg);
    }
  };

  /**
   * Aggregates the netBalanceChanges array by combining entries with the same from, to, and token.
   * @param {Array} netBalanceChanges - The array of net balance changes to be aggregated.
   * @returns {Object} An object containing the aggregated balance changes.
   */
  const aggregateNetBalanceChanges = (netBalanceChanges: any[]) => {
    const aggregatedEntries = netBalanceChanges.reduce(
      (acc: any, curr: any) => {
        const { from, to, token } = curr;

        // Check if from, to, and token.contract exist
        if (from && to && token?.contract) {
          const key = `${from}-${to}-${token.contract}`;

          if (acc[key]) {
            acc[key].value = (
              BigInt(acc[key].value) + BigInt(curr.value)
            ).toString();
            acc[key].usdValue = (acc[key].usdValue || 0) + (curr.usdValue || 0);
          } else {
            acc[key] = { ...curr };
          }
        }

        return acc;
      },
      {}
    );

    const unchangedEntries = netBalanceChanges.filter(
      (entry) => !(entry.from && entry.to && entry.token?.contract)
    );

    return [...Object.values(aggregatedEntries), ...unchangedEntries];
  };

  /**
   * Simulate a signature based on provided data
   * @param signature - Array containing signature data
   * @param network - Network identifier
   * @returns Simulation result
   */
  const getSignatureSimulation = async (
    signature: string[],
    network: string
  ) => {
    try {
      // Parse the signature data
      const parsedSignature = JSON.parse(signature[1]);

      // Prepare data for signature simulation
      const signatureSimulationData = {
        types: parsedSignature.types,
        primaryType: parsedSignature.primaryType,
        domain: parsedSignature.domain,
        message: parsedSignature.message,
        fromAddress: signature[0],
        network: network,
      };

      // Send data to simulator backend for signature simulation

      const simulation = await axios(
        getSimulatedSignature(signatureSimulationData)
      );

      // Aggregate netBalanceChanges
      //console.log('simulation AAA', simulation);
      if (simulation?.data?.error) {
        throw new Error(simulation?.data?.error);
      }
      if (simulation?.data?.netBalanceChanges) {
        //console.log('netBalanceChanges A', simulation.data.netBalanceChanges);
        simulation.data.netBalanceChanges = Object.values(
          aggregateNetBalanceChanges(simulation.data.netBalanceChanges)
        );
        //console.log('netBalanceChanges BBB', simulation.data.netBalanceChanges);
      }

      // Log the simulation result
      debugLog(simulation);

      // Retrieve token information and error details
      const tokenInfo = NETWORK_INFO[network || '0x1'];
      const errorInfo = getErrorInformation(simulation, undefined, tokenInfo);

      // Update EIP information with simulation result and error details
      updateEIPInformation({ loading: false, error: errorInfo });

      return simulation;
    } catch (error: any) {
      // Handle errors during signature simulation
      console.error(error);

      // Retrieve token information and error details
      const tokenInfo = NETWORK_INFO[network || '0x1'];
      const errorInfo = getErrorInformation(undefined, error, tokenInfo);

      // Update EIP information with error details
      updateEIPInformation({
        loading: false,
        error: errorInfo,
      });
    }
  };

  /**
   * Call API to simulate a transaction
   * @param transaction - Transaction data
   * @param network - Network identifier
   * @returns Simulation response
   */
  const getTransactionSimulation = async (
    transaction: Transaction,
    network: string
  ) => {
    try {
      const transactionEventData = {
        network: network,
        value: transaction?.value || '0',
        gas: transaction.gas,
        from: transaction.from,
        to: transaction?.to, // will be undefined if a contract is being deployed
        data: transaction?.data || '0x',
      };

      if (transaction?.from && transaction?.to) {
        const ensPromises = new Map<string, Promise<string | null>>([
          [
            transaction.from,
            Promise.resolve(
              getAddressLabel(transaction.from, network)?.then(
                (result) => result.label
              ) || null
            ),
          ],
          [
            transaction.to,
            Promise.resolve(
              getAddressLabel(transaction.to, network)?.then(
                (result) => result.label
              ) || null
            ),
          ],
        ]);
        updateEIPInformation({ addressLabels: ensPromises });
      } else {
        updateEIPInformation({ addressLabels: undefined });
      }

      const response = await axios(
        getSimulatedEipTransaction(transactionEventData)
      );

      // Aggregate netBalanceChanges
      // console.log('simulation CCC', { response });
      if (response?.data?.netBalanceChanges) {
        //console.log('netBalanceChanges C', response.data.netBalanceChanges);
        response.data.netBalanceChanges = Object.values(
          aggregateNetBalanceChanges(response.data.netBalanceChanges)
        );
        // console.log('netBalanceChanges D', response.data.netBalanceChanges);
      }

      debugLog(response);

      const user: string | undefined =
        localStorage.getItem('address') || response?.data?.from;
      const eipType = response?.data.type;

      setTransactionType(eipType);
      updateEIPInformation({
        from: user,
        type: eipType,
        contractAddress: response?.data.to,
        spender: response?.data.spender,
        deepSimulationData: response?.data.deepSimulationData
          ? (response.data.deepSimulationData as DeepSimulation)
          : undefined,
      });

      const tokenInfo = NETWORK_INFO[network || '0x1'];
      const errorInfo = getErrorInformation(response, undefined, tokenInfo);
      updateEIPInformation({ loading: false, error: errorInfo });
      setTransactionData(response?.data);
      return response;
    } catch (error: any) {
      console.error(error);
      const tokenInfo = NETWORK_INFO[network || '0x1'];
      const errorInfo = getErrorInformation(undefined, error, tokenInfo);
      updateEIPInformation({
        loading: false,
        error: errorInfo,
      });
    }
  };

  const setTransactionData = (response: any) => {
    setTransactionSimulation(response);
    if (
      response.deepSimulationData &&
      (response.deepSimulationData as DeepSimulation).type ===
        DeepSimulationType.ENS_SIGNATURE_REQUEST
    ) {
      setTransactionType(
        response.deepSimulationData
          ? (response.deepSimulationData as DeepSimulation).type
          : response.type
      );
      return;
    }
    setTransactionType(
      response.type === 'signature'
        ? EIPTransactionTypes.UNKNOWN
        : response.type
    );
  };
  /*
  console.log('Simulator provider', {
    eipGlobalInformation,
    transactionSimulation,
    transactionType,
  });
*/

  return (
    <SimulationContext.Provider
      value={{
        eipGlobalInformation,
        transactionSimulation,
        transactionType,
      }}
    >
      {eipGlobalInformation.loading ? <SkeletonBanner /> : <>{children}</>}
    </SimulationContext.Provider>
  );
};

export { SimulationContext, SimulationProvider };
