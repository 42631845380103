interface ITransactionRowContainerProps {
  children: React.ReactNode;
  loading: boolean;
}

const TransactionRowContainer: React.FC<ITransactionRowContainerProps> = ({
  children,
  loading,
}) => {
  /*return (
    <div
      className={`${
        loading ? 'animate-tailwindPulse bg-gray-300' : 'shadow-md'
      } w-[332px] h-[72px] bg-white rounded-lg p-2 overflow-hidden mx-auto`}
    >
      {!loading && children}
    </div>
  );*/

  return <div className={`interacting-item`}>{!loading && children}</div>;
};

export default TransactionRowContainer;
