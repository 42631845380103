import { useState } from 'react';
//import { Popup } from 'semantic-ui-react';
import {
  defaultCurrencyIcon,
  verifiedIcon,
} from '../../../helpers/icons/icons';
import { IERC20BalanceChange } from '../../../helpers/interfaces/dataTypes.interface';
//import CurrencyContainer from '../../Atoms/CurrencyContainer';
import TransactionRowContainer from '../../Containers/TransactionRowContainer';

export interface IERC20ApproveProps {
  balanceChange: IERC20BalanceChange;
}

const ERC20Approve: React.FC<IERC20ApproveProps> = ({ balanceChange }) => {
  /*const isRevoke = Number(balanceChange?.value) === 0;

  const tokenStatus = balanceChange.verified ? (
    <Popup size="mini" position="top left" trigger={verifiedIcon}>
      Fire verifies tokens from TokenList
    </Popup>
  ) : (
    <></>
  );*/

  /*const expirationDate = (
    dateString: string | undefined
  ): string | undefined => {
    if (!dateString) return undefined;
    const [year, month, day] = new Date(dateString)
      .toISOString()
      .split('T')[0]
      .split('-');
    return `${year}-${month}-${day}`;
  };*/

  const isValidLogo = !!(balanceChange.logo && balanceChange.logo.length);

  const Logo: React.FC = () => {
    const [didImageError, setDidImageError] = useState<boolean>(false);

    const ContentImage: JSX.Element = (
      <img
        className="h-[32px] w-[32px] rounded-full"
        src={balanceChange.logo}
        alt={balanceChange.symbol}
        onError={() => setDidImageError(true)}
      />
    );
    if (isValidLogo && !didImageError) {
      return <>{ContentImage}</>;
    }
    return <div className="pr-2">{defaultCurrencyIcon}</div>;
  };
  /*
  return (
    <TransactionRowContainer loading={false}>
      <div className="grid grid-cols-10 justify-between">
        <div className="flex flex-col justify-between p-0.5 col-span-6">
          <div className="flex flex-row items-center">
            <Logo />
            <span className="font-ClashDisplay text-xl font-medium text-secondary-800">
              {balanceChange.symbol || 'Unknown'}
            </span>
            <div className="ml-1.5">{tokenStatus}</div>
          </div>
          <div className="flex flex-row font-Manrope w-full font-medium text-sm text-secondary-800 self-end overflow-hidden whitespace-nowrap text-overflow-ellipsis">
            {`Purpose: ${isRevoke ? 'Revoke' : 'Approval'}`}
          </div>
        </div>
        <div className="relative">
          <CurrencyContainer usdValue={undefined} value={balanceChange.value} />
          <div
            className={
              balanceChange.expiration
                ? `absolute bottom-1 left-10 w-[100px] font-ClashDisplay text-xs`
                : 'hidden'
            }
          >
            {`until ${expirationDate(balanceChange.expiration)}`}
          </div>
        </div>
      </div>
    </TransactionRowContainer>
  );*/

  return (
    <TransactionRowContainer loading={false}>
      <div className="interacting-item-label">
        <div className="interacting-item-token">
          <Logo />
        </div>
        <div>
          <span>
            {balanceChange.symbol}
            {balanceChange.verified ? verifiedIcon : null}
          </span>
        </div>
      </div>
      <div className="interacting-item-value">
        <div className="approving-value">
          {balanceChange.value} {balanceChange.symbol}
        </div>
      </div>
    </TransactionRowContainer>
  );
};

export default ERC20Approve;
