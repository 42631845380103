import { useEffect } from 'react';
import { KERBERUS_SUPPORT_URL } from '../../helpers/constants/application.constants';
import { ErrorTypes } from '../../helpers/enums/application.enums';
import { GeneralSimulationError } from '../../helpers/icons/icons';
import ErrorModal from './ErrorModal';

const generalError = {
  errorIcon: GeneralSimulationError,
  title: ErrorTypes.FRONTEND_ERROR,
  messageComponent: (
    <div className="text-secondary-800">
      <div className="mb-2 font-bold text-secondary-800">How to proceed:</div>
      <ol className="list-decimal list-inside text-left space-y-1">
        <li>Reject your transaction.</li>
        <li>Rerun the most recent contract interaction.</li>
        <li>
          If the error keeps occurring, report it{' '}
          <a
            href={KERBERUS_SUPPORT_URL}
            target="_blank"
            style={{ color: 'inherit' }}
          >
            <u>here!</u>
          </a>
        </li>
      </ol>
    </div>
  ),
};

//const ErrorFallback: React.FC = () => <ErrorModal error={generalError} />;
const ErrorFallback: React.FC = () => {
  useEffect(() => {
    window.parent.postMessage(
      {
        type: 'md_translator',
        sourceUrl: 'SIMULATION_ERROR',
        status: false, // or 'false' depending on the condition you want to test
      },
      '*'
    );
    window.close();
  }, []);

  return <ErrorModal error={generalError} />;
};

export default ErrorFallback;
