import { ethers } from 'ethers';
import { contracts } from './constants/NftContracts';
import { NETWORK_INFO } from './constants/application.constants';
import { exclamation_triangle_filled } from './icons/icons';

export const contractBannerColor = (signature: boolean): string => {
  return signature ? 'bg-error-800' : 'bg-secondary-800';
};

export const contractTextColor = (signature: boolean): string => {
  return signature ? 'text-secondary-800' : 'text-white';
};

export const warningIcon = (signature: boolean): JSX.Element => {
  return signature ? exclamation_triangle_filled() : <></>;
};

export const formatUSD = (value: number) => {
  const formatValue = (num: number) => {
    return `$${num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  };

  if (value === 0 || !value) {
    return '$0.00';
  }
  if (value < 0.01) {
    return '< $0.01';
  }
  if (value.toString().includes('e')) {
    // formatting to remove scientific notation
    // so instead of 1e-6 we get 0.000001
    const fixedValue = parseFloat(
      value.toFixed(parseInt(value.toString().split('-')[1]))
    );
    return formatValue(fixedValue);
  }
  return formatValue(value);
};

export const capitalizeString = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const formatCurrencyWithoutRounding = (
  value: string,
  decimals?: number | undefined
) => {
  const tokenDecimals = decimals || 18;

  const max256 = parseInt(
    '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
    16
  ).toString(16);

  const max160 = parseInt(
    '0xffffffffffffffffffffffffffffffffffffffff',
    16
  ).toString(16);

  if (
    parseInt(value).toString(16) === max256 ||
    parseInt(value).toString(16) === max160
  ) {
    return 'Infinite';
  }

  const result = parseInt(value) / Math.pow(10, tokenDecimals);

  if (result.toString().includes('e')) {
    // formatting to remove scientific notation
    // so instead of 1e-6 we get 0.000001
    return result.toFixed(parseInt(result.toString().split('-')[1]));
  }

  return result;
};

export const formatCurrency = (
  value: string,
  decimals?: number | undefined
) => {
  const tokenDecimals = decimals || 18;
  const max256 = parseInt(
    '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
    16
  ).toString(16);

  const max160 = parseInt(
    '0xffffffffffffffffffffffffffffffffffffffff',
    16
  ).toString(16);

  if (
    parseInt(value).toString(16) === max256 ||
    parseInt(value).toString(16) === max160
  ) {
    return 'Infinite';
  }

  const result = parseInt(value) / Math.pow(10, tokenDecimals);

  if (result === 0) {
    return 0;
  }

  if (result < 0.001) {
    return '< 0.001';
  }

  const roundedResult = Math.round(result * 1000) / 1000;

  return roundedResult;
};

export const reduceAddress = (address: string) => {
  if (address.length < 12) {
    return address.charAt(0).toUpperCase() + address.slice(1);
  }
  try {
    if (address !== 'validators') {
      return address;
    }
    return address.charAt(0).toUpperCase() + address.slice(1);
  } catch (error) {
    return '';
  }
};

export const reduceName = (name: string | undefined | null) => {
  if (!name) return undefined;

  if (name.length > 30) {
    return `${name.slice(0, 31)}...`;
  } else return name;
};

export const getBlockExplorerUrl = (input: string, chainId: string): string => {
  return NETWORK_INFO[chainId]
    ? `${NETWORK_INFO[chainId].explorer}address/${input}`
    : '';
};

//* danor - todo - get all labels from etherscan labels instead of using a static source 'contracts'
/**
 * Get the contract name based on the address and network
 * @param address - Contract address
 * @param network - Network identifier
 * @returns Contract name or null
 */
export const getContractName = (
  address: string,
  network: string
): string | undefined | null => {
  if (!address) return null;
  if (address.substring(0, 2) !== '0x') return address; // If the address was already labeled, return it

  if (!contracts[network]) {
    return null;
  }

  return contracts[network][address.toLowerCase()]
    ? contracts[network][address.toLowerCase()].name
    : null;
};

export const resolveENSName = async (
  address: string
): Promise<string | null> => {
  const provider = new ethers.providers.AlchemyProvider(
    'homestead',
    'G-Uc_pdqbIAwHnom2xALqLjaYIJbPDvN'
  );

  if (!address) return null;

  try {
    return address.substring(0, 2) !== '0x' //if we don't find basic address formatting just return null since we know it'll fail
      ? null
      : provider.lookupAddress(address);
  } catch (e) {
    console.error(e);
    return null;
  }
};

/**
 * Retrieves the address label and logo URL from the Kerberus metadata API.
 *
 * @param {string} address - The address to retrieve the label for.
 * @param {string} network - The network identifier.
 * @returns {Promise<{ label: string | null, logoUrl: string | null }>} A promise that resolves to an object containing the label and logo URL.
 */
export const getKerberusAddressLabel = async (
  address: string,
  network: string
) => {
  try {
    if (!address || !network)
      return {
        label: null,
        logoUrl: null,
      };
    /*
    console.log(
      `getKerberusAddressLabel`,
      address,
      network,
      `${process.env.REACT_APP_KERBERUS_DETECTION_API_URI}/services/metadata/${network}/${address}`
    );
    */
    const response = await fetch(
      `${process.env.REACT_APP_KERBERUS_DETECTION_API_URI}/services/metadata/${network}/${address}`
    );
    const data = await response.json();
    const { etherscan: etherscanMetadata, opensea: openseaMetadata } = data;

    const {
      nametag = undefined,
      other_attributes = [],
      labels = [],
    } = etherscanMetadata || {}; // Provide a default empty object if etherscanMetadata is undefined

    // Extract the label from the available metadata
    var label =
      openseaMetadata?.name ??
      nametag ??
      other_attributes[0] ??
      labels[0] ??
      null;

    if (labels && labels.length > 0) {
      const isPhishing = labels.some(
        (label: string) => label === 'Phish / Hack'
      );

      if (isPhishing) {
        label = 'Fake_Phishing';
      }
    }

    //extract logo URL
    let logoUrl;
    if (openseaMetadata?.imageUrl) {
      logoUrl = openseaMetadata?.imageUrl;
    } else if (etherscanMetadata?.url) {
      const domain = getBaseUrl(etherscanMetadata.url);
      if (domain) {
        logoUrl = `https://www.google.com/s2/favicons?sz=32&domain_url=${domain}`;
      }
    }

    return {
      label: label ?? null,
      logoUrl: logoUrl ?? null,
    };
  } catch (error) {
    console.error('Error retrieving Kerberus address label:', error);
    return {
      label: null,
      logoUrl: null,
    };
  }
};

/**
 * Retrieves the address label and logo URL for a given address and network.
 * @param address - The address to retrieve the label for.
 * @param network - The network identifier.
 * @returns A promise that resolves to an object containing the label and logo URL.
 */
export const getAddressLabel = async (
  address: string,
  network: string
): Promise<{ label: string | null; logoUrl: string | null }> => {
  if (!address || !network) {
    return { label: null, logoUrl: null };
  }

  //console.log('getAddressLabel', address, network);
  try {
    // First, try to retrieve the address metadata from the Kerberus metadata collector
    const { label: kerberusLabel, logoUrl: kerberusLogoUrl } =
      await getKerberusAddressLabel(address, network);

    if (kerberusLabel) {
      //console.log('kerberusLabel - ON', { kerberusLabel, kerberusLogoUrl });
      return { label: kerberusLabel, logoUrl: kerberusLogoUrl };
    }
    //console.log('kerberusLabel - OFF', { kerberusLabel, kerberusLogoUrl });

    // If the address label is not found in Kerberus metadata, try to get from static contracts list
    const contractName = getContractName(address, network);
    if (contractName) {
      return { label: contractName, logoUrl: null };
    }

    // If the contract name is not found and the network is mainnet (0x1),
    // attempt to resolve the ENS name for the address
    if (network === '0x1') {
      const ensName = await resolveENSName(address);

      if (ensName) {
        return { label: ensName, logoUrl: null };
      }
    }

    // If no label is found, return null for both label and logoUrl
    return { label: null, logoUrl: null };
  } catch (error) {
    console.error('Error retrieving address label:', error);
    return { label: null, logoUrl: null };
  }
};

export const hexToUtf8 = (hexString: string) => {
  try {
    const decoded = Buffer.from(hexString[0].slice(2), 'hex').toString('utf-8');
    return decoded.replace(/\n/g, '\n');
  } catch (e) {
    return hexString;
  }
};

//strip everything before and after the base url
export const getBaseUrl = (sourceUrl: string) => {
  return sourceUrl?.replace(/^https?:\/\/([^/]+).*$/, '$1');
};

export const SplitNetBalanceChanges = (
  netBalanceChanges: any[],
  userAddress: string
) => {
  const incomingTxs: any[] = [];
  const outgoingTxs: any[] = [];
  const validators: any[] = [];

  if (!!netBalanceChanges?.length) {
    netBalanceChanges.forEach((transaction: any) => {
      if (transaction.to === 'validators') {
        validators.push(transaction);
      } else if (
        ['erc20Approve', 'erc721Approve', 'erc1155Approve'].includes(
          transaction.type
        )
      ) {
        outgoingTxs.push({
          ...transaction,
        });
      } else if (
        transaction?.from?.toLowerCase() === userAddress.toLowerCase()
      ) {
        outgoingTxs.push(transaction);
      } else if (transaction?.to?.toLowerCase() === userAddress.toLowerCase()) {
        incomingTxs.push(transaction);
      }
    });
  }

  return { incomingTxs, outgoingTxs, validators };
};

export const parseSpenderAddress = (json: any) => {
  try {
    const data = JSON.parse(json);

    if (data && typeof data === 'object') {
      const findSpender = (obj: any): any => {
        if ('spender' in obj) {
          return obj.spender;
        } else if ('offerer' in obj) {
          return obj.offerer; // Seaport
        } else if ('trader' in obj) {
          return obj.trader; // Blur
        }

        for (const key in obj) {
          if (typeof obj[key] === 'object') {
            const result = findSpender(obj[key]);
            if (result) {
              return result;
            }
          }
        }

        return undefined;
      };

      return findSpender(data);
    }
  } catch (error) {
    //catch parsing error but ignore because it's noisy in the console and doesn't need to be handled
  }

  return undefined;
};

// Type guard to check if an array contains only strings
export const isStringArrayTypeguard = (value: any[]): value is string[] => {
  return value.every((element) => typeof element === 'string');
};
