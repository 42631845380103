export const land = (size: string = '43', showBorder: boolean = true) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="21.5" cy="21.5" r="21.5" fill="white" />
    {showBorder && (
      <circle
        cx="21.5"
        cy="21.5"
        r="21.0789"
        stroke="#312E45"
        strokeOpacity="0.14"
        strokeWidth="0.842105"
      />
    )}
    <rect x="2" y="20" width="2" height="4" fill="black" />
    <rect
      x="4"
      y="22"
      width="2"
      height="5"
      transform="rotate(-90 4 22)"
      fill="#8DD7B9"
    />
    <rect
      x="9"
      y="20"
      width="2"
      height="5"
      transform="rotate(-90 9 20)"
      fill="#8DD7B9"
    />
    <rect
      x="14"
      y="22"
      width="2"
      height="5"
      transform="rotate(-90 14 22)"
      fill="#8DD7B9"
    />
    <rect
      x="9"
      y="24"
      width="2"
      height="5"
      transform="rotate(-90 9 24)"
      fill="#8DD7B9"
    />
    <rect
      x="14"
      y="18"
      width="2"
      height="5"
      transform="rotate(-90 14 18)"
      fill="#8DD7B9"
    />
    <rect
      x="19"
      y="20"
      width="2"
      height="5"
      transform="rotate(-90 19 20)"
      fill="#8DD7B9"
    />
    <rect
      x="19"
      y="16"
      width="2"
      height="5"
      transform="rotate(-90 19 16)"
      fill="#8DD7B9"
    />
    <rect
      x="24"
      y="18"
      width="2"
      height="5"
      transform="rotate(-90 24 18)"
      fill="#8DD7B9"
    />
    <rect
      x="29"
      y="20"
      width="2"
      height="5"
      transform="rotate(-90 29 20)"
      fill="#8DD7B9"
    />
    <rect
      x="24"
      y="22"
      width="2"
      height="5"
      transform="rotate(-90 24 22)"
      fill="#8DD7B9"
    />
    <rect
      x="14"
      y="26"
      width="2"
      height="5"
      transform="rotate(-90 14 26)"
      fill="#8DD7B9"
    />
    <rect
      x="19"
      y="28"
      width="2"
      height="5"
      transform="rotate(-90 19 28)"
      fill="#8DD7B9"
    />
    <rect
      x="19"
      y="24"
      width="2"
      height="5"
      transform="rotate(-90 19 24)"
      fill="#8DD7B9"
    />
    <rect
      x="29"
      y="24"
      width="2"
      height="5"
      transform="rotate(-90 29 24)"
      fill="#8DD7B9"
    />
    <rect
      x="24"
      y="26"
      width="2"
      height="5"
      transform="rotate(-90 24 26)"
      fill="#8DD7B9"
    />
    <rect
      x="34"
      y="22"
      width="2"
      height="5"
      transform="rotate(-90 34 22)"
      fill="#8DD7B9"
    />
    <rect
      x="4"
      y="20"
      width="2"
      height="5"
      transform="rotate(-90 4 20)"
      fill="black"
    />
    <rect
      x="4"
      y="20"
      width="2"
      height="5"
      transform="rotate(-90 4 20)"
      fill="black"
    />
    <rect
      x="9"
      y="18"
      width="2"
      height="5"
      transform="rotate(-90 9 18)"
      fill="black"
    />
    <rect
      x="9"
      y="18"
      width="2"
      height="5"
      transform="rotate(-90 9 18)"
      fill="black"
    />
    <rect
      x="14"
      y="16"
      width="2"
      height="5"
      transform="rotate(-90 14 16)"
      fill="black"
    />
    <rect
      x="14"
      y="16"
      width="2"
      height="5"
      transform="rotate(-90 14 16)"
      fill="black"
    />
    <rect
      x="19"
      y="14"
      width="2"
      height="5"
      transform="rotate(-90 19 14)"
      fill="black"
    />
    <rect
      x="19"
      y="14"
      width="2"
      height="5"
      transform="rotate(-90 19 14)"
      fill="black"
    />
    <rect
      x="24"
      y="16"
      width="2"
      height="5"
      transform="rotate(-90 24 16)"
      fill="black"
    />
    <rect
      x="24"
      y="16"
      width="2"
      height="5"
      transform="rotate(-90 24 16)"
      fill="black"
    />
    <rect
      x="29"
      y="18"
      width="2"
      height="5"
      transform="rotate(-90 29 18)"
      fill="black"
    />
    <rect
      x="29"
      y="18"
      width="2"
      height="5"
      transform="rotate(-90 29 18)"
      fill="black"
    />
    <rect
      x="34"
      y="20"
      width="2"
      height="5"
      transform="rotate(-90 34 20)"
      fill="black"
    />
    <rect
      x="34"
      y="20"
      width="2"
      height="5"
      transform="rotate(-90 34 20)"
      fill="black"
    />
    <rect x="39" y="20" width="2" height="4" fill="black" />
    <rect x="39" y="20" width="2" height="4" fill="black" />
    <rect
      x="4"
      y="26"
      width="2"
      height="5"
      transform="rotate(-90 4 26)"
      fill="black"
    />
    <rect
      x="4"
      y="26"
      width="2"
      height="5"
      transform="rotate(-90 4 26)"
      fill="black"
    />
    <rect
      x="9"
      y="28"
      width="2"
      height="5"
      transform="rotate(-90 9 28)"
      fill="black"
    />
    <rect
      x="9"
      y="28"
      width="2"
      height="5"
      transform="rotate(-90 9 28)"
      fill="black"
    />
    <rect
      x="14"
      y="30"
      width="2"
      height="5"
      transform="rotate(-90 14 30)"
      fill="black"
    />
    <rect
      x="14"
      y="30"
      width="2"
      height="5"
      transform="rotate(-90 14 30)"
      fill="black"
    />
    <rect
      x="19"
      y="32"
      width="2"
      height="5"
      transform="rotate(-90 19 32)"
      fill="black"
    />
    <rect
      x="19"
      y="32"
      width="2"
      height="5"
      transform="rotate(-90 19 32)"
      fill="black"
    />
    <rect
      x="24"
      y="30"
      width="2"
      height="5"
      transform="rotate(-90 24 30)"
      fill="black"
    />
    <rect
      x="24"
      y="30"
      width="2"
      height="5"
      transform="rotate(-90 24 30)"
      fill="black"
    />
    <rect
      x="29"
      y="28"
      width="2"
      height="5"
      transform="rotate(-90 29 28)"
      fill="black"
    />
    <rect
      x="29"
      y="28"
      width="2"
      height="5"
      transform="rotate(-90 29 28)"
      fill="black"
    />
    <rect
      x="34"
      y="26"
      width="2"
      height="5"
      transform="rotate(-90 34 26)"
      fill="black"
    />
    <rect
      x="34"
      y="26"
      width="2"
      height="5"
      transform="rotate(-90 34 26)"
      fill="black"
    />
    <rect
      x="4"
      y="24"
      width="2"
      height="5"
      transform="rotate(-90 4 24)"
      fill="#53B77F"
    />
    <rect
      x="9"
      y="22"
      width="2"
      height="5"
      transform="rotate(-90 9 22)"
      fill="#53B77F"
    />
    <rect
      x="9"
      y="26"
      width="2"
      height="5"
      transform="rotate(-90 9 26)"
      fill="#53B77F"
    />
    <rect
      x="14"
      y="20"
      width="2"
      height="5"
      transform="rotate(-90 14 20)"
      fill="#53B77F"
    />
    <rect
      x="14"
      y="24"
      width="2"
      height="5"
      transform="rotate(-90 14 24)"
      fill="#53B77F"
    />
    <rect
      x="14"
      y="28"
      width="2"
      height="5"
      transform="rotate(-90 14 28)"
      fill="#53B77F"
    />
    <rect
      x="19"
      y="26"
      width="2"
      height="5"
      transform="rotate(-90 19 26)"
      fill="#53B77F"
    />
    <rect
      x="19"
      y="30"
      width="2"
      height="5"
      transform="rotate(-90 19 30)"
      fill="#53B77F"
    />
    <rect
      x="19"
      y="22"
      width="2"
      height="5"
      transform="rotate(-90 19 22)"
      fill="#53B77F"
    />
    <rect
      x="24"
      y="24"
      width="2"
      height="5"
      transform="rotate(-90 24 24)"
      fill="#53B77F"
    />
    <rect
      x="24"
      y="28"
      width="2"
      height="5"
      transform="rotate(-90 24 28)"
      fill="#53B77F"
    />
    <rect
      x="29"
      y="26"
      width="2"
      height="5"
      transform="rotate(-90 29 26)"
      fill="#53B77F"
    />
    <rect
      x="24"
      y="20"
      width="2"
      height="5"
      transform="rotate(-90 24 20)"
      fill="#53B77F"
    />
    <rect
      x="34"
      y="24"
      width="2"
      height="5"
      transform="rotate(-90 34 24)"
      fill="#53B77F"
    />
    <rect
      x="29"
      y="22"
      width="2"
      height="5"
      transform="rotate(-90 29 22)"
      fill="#53B77F"
    />
    <rect
      x="19"
      y="18"
      width="2"
      height="5"
      transform="rotate(-90 19 18)"
      fill="#53B77F"
    />
  </svg>
);
