import { ErrorBoundary } from '@sentry/react';
import ErrorFallback from './components/Error/ErrorFallback';
import Simulator from './components/Simulation/Simulator';
import { SimulationProvider } from './contexts/SimulationContext';
import { useEffect } from 'react';

//test 1
const App: React.FC = () => {
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://use.typekit.net/gbk4uga.css';
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return (
    <ErrorBoundary fallback={<ErrorFallback />}>
      <SimulationProvider>
        <Simulator />
      </SimulationProvider>
    </ErrorBoundary>
  );
};

export default App;
