export enum EIPTransactionDictionary {
  ERC20_TRANSFER = 'erc20Transfer',
  ERC20_APPROVE = 'erc20Approve',
  ERC721_TRANSFER = 'erc721Transfer',
  ERC721_APPROVE = 'erc721Approve',
  ERC1155_APPROVE = 'erc1155Approve',
}

export enum EIPTransactionTypes {
  ERC20 = 'erc20',
  ERC721 = 'erc721',
  ERC1155 = 'erc1155',
  ETH = 'eth',
  ETH_SIG = 'eth_sign',
  PERSONAL_SIG = 'personal_sign',
  UNKNOWN = 'unknown',
}

export enum SignatureTypes {
  EMP = 'unknown',
  UNKNOWN = 'unknown',
  ETH_SIG = 'eth_sign',
  EIP_191_PERSONAL_SIGN = 'personal_sign',
  EIP_4361_PERSONAL_SIGN = 'siwe_personal_sign',
  SIGN_TYPED_DATA = 'eth_signTypedData',
  SIGN_TYPED_DATA_V1 = 'eth_signTypedData_v1',
  SIGN_TYPED_DATA_V3 = 'eth_signTypedData_v3',
  SIGN_TYPED_DATA_V4 = 'eth_signTypedData_v4',
}

export enum ScamTypesEnum {
  MALICIOUS_ADDRESS = 'malicious_address',
  MALICIOUS_SIGNATURE = 'malicious_signature',
}

export enum ErrorTypes {
  INSUFFICIENT_FUNDS = 'Insufficient Funds',
  INSUFFICIENT_BALANCE = 'Insufficient Balance',
  NOT_VERIFIED = 'Contract source code not verified',
  INSUFFICIENT_GAS_LIMIT = 'Out of Gas',
  RATE_LIMIT = 'Rate Limit',
  GENERAL_ERROR = 'Simulation Error',
  FRONTEND_ERROR = 'Internal Error',
}

export enum DeepSimulationType {
  ENS_SIGNATURE_REQUEST = 'SignatureRequest',
  ENS_REGISTER = 'ENSRegister',
  POLYGON_NATIVE_BRIDGE_ETH = 'DepositEtherFor',
  POLYGON_NATIVE_BRIDGE_ERC20 = 'DepositFor',
  POLYGON_NATIVE_BRIDGE_WITHDRAW = 'Withdraw',
  PHILAND_LAND_DEPOSIT = 'LandDeposit',
  PHILAND_LAND_WITHDRAW = 'LandWithdraw',
  PHILAND_LAND_CLAIM = 'LandClaim',
  PHILAND_LAND_CREATE = 'LandCreate',
  PHILAND_LAND_SAVE = 'LandSave',
  PHILAND_LAND_BUY = 'LandBuy',
  PHILAND_LAND_BUY_AND_DEPOSIT = 'LandBuyAndDeposit',
}

export const philandDeepSimulationType = [
  DeepSimulationType.PHILAND_LAND_DEPOSIT,
  DeepSimulationType.PHILAND_LAND_WITHDRAW,
  DeepSimulationType.PHILAND_LAND_CLAIM,
  DeepSimulationType.PHILAND_LAND_CREATE,
  DeepSimulationType.PHILAND_LAND_SAVE,
  DeepSimulationType.PHILAND_LAND_BUY,
  DeepSimulationType.PHILAND_LAND_BUY_AND_DEPOSIT,
];

export enum PhilandDeepSimulationAddress {
  PHILAND_NFT_CONTRACT = '0x754e78bc0f7b487d304552810a5254497084970c',
  PHILAND_LAND_CONTRACT = '0xe8b6395d223c9d3d85e162f2cb2023bc9088a908',
  PHILAND_REGISTRY_CONTRACT = '0x6532b97295a0728880ae81d5cd7248f32e24e39a',
  PHILAND_SHOP_CONTRACT = '0xc6e5240054dbe92bde25546cf0c72dc499c41ca8',
}

export enum WalletProvidersEnum {
  BRAVE_WALLET = 'Brave Wallet',
  COINBASE_WALLET = 'Coinbase Wallet',
  EXODUS_WALLET = 'Exodus Wallet',
  FRAME_WALLET = 'Frame Wallet',
  METAMASK_WALLET = 'MetaMask Wallet',
  ONEINCH_ANDROID_WALLET = 'OneInch Android Wallet',
  ONEINCH_IOS_WALLET = 'OneInch IOS Wallet',
  OPERA_WALLET = 'Opera Wallet',
  TALLY_WALLET = 'Tally Wallet',
  TOKENPOCKET_WALLET = 'TokenPocket Wallet',
  TOKENARY_WALLET = 'Tokenary Wallet',
  TRUST_WALLET = 'Trust Wallet',
  FIRE_WALLET = 'Fire Wallet',
}

export enum TrustLevelEnum {
  UNKNOWN = 'unknown', //we got not response or couldn't make a determination one way or the other
  DANGER = 'danger', //guaranteed loss of funds is associated with this counterparty
  LOW = 'low', //counterparty has been flagged as malicious
  MEDIUM = 'medium', //no flags but risky transaction (e.g. large value transfer on a new domain using verified contract)
  HIGH = 'high', //verified contract or domain
}

export enum RiskSeverityEnum {
  NONE = 'none',
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export enum UITargetEnum {
  BANNER_VIEW = 'bannerView',
  INTERACTION_INFO_VIEW = 'interactionInfoView',
  WALLET_CHANGES_VIEW = 'walletChangesView',
}

export enum PersonalSignatureRiskReasonEnum {
  SIWE_ORIGIN_MISMATCH = 'siwe_invalid_origin',
  SIWE_INVALID_ADDRESS = 'siwe_invalid_address',
  OBFUSCATED_MESSAGE = 'obfuscated_message',
}

export enum SecurityWarningSourceEnum {
  FIRE = 'fire',
}
