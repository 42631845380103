//import { Popup } from 'semantic-ui-react';
import {
  getBlockExplorerUrl,
  reduceAddress,
  reduceName,
} from '../../helpers/methods';

interface IAddressProps {
  className?: string;
  overrideClassDefaults?: boolean;
  labeledAddress?: string | null | undefined;
  position?: 'top right' | 'top center';
  network: string;
  address: string;
}

const Address: React.FC<IAddressProps> = ({
  labeledAddress,
  address,
  network,
}) => {
  const formattedAddress = reduceName(labeledAddress) ?? reduceAddress(address);
  const tooltipTrigger = (
    <a
      target="_blank"
      className={` whitespace-nowrap ${
        labeledAddress?.startsWith('ALERT') ? 'text-red-600' : 'fire-link'
      }`}
      href={getBlockExplorerUrl(address, network)}
      title={address}
    >
      {formattedAddress}
    </a>
  );
  /*
  const tooltipContent = (
    <div className="text-center">
      {labeledAddress && (
        <>
          <span>{labeledAddress}</span>
          <br />
        </>
      )}
      <span>{address}</span>
    </div>
  );*/

  return (
    <div
    /*className={`${className} ${
        !overrideClassDefaults &&
        'font-Manrope font-medium text-sm text-secondary-800'
      } `}*/
    >
      {tooltipTrigger}
      {/* <Popup
        size="mini"
        position={position ? position : 'top left'}
        trigger={tooltipTrigger}
      >
        {tooltipContent}
      </Popup> */}
    </div>
  );
};

export default Address;
